<template>
  <div>
    <div class="card card-custom">
      <div class="card-body p-0">
        <div
          class="wizard wizard-2"
          id="kt_wizard_v2"
          data-wizard-state="step-first"
          data-wizard-clickable="true"
        >
          <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
            <div class="row mb-3">
              <div class="col-6">
                <h4>
                  <router-link :to="{name:'admission-process'}">
                    <i class="fas fa-arrow-left"></i>
                  </router-link>Add Admission Process
                </h4>
              </div>
              <div class="col-6 text-right"></div>
            </div>
            <div class="row">
              <div class="col-6 form-group">
                <label for class="float-left">Title</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="admission.title"
                  placeholder="Title"
                />
                <span class="text-danger" v-if="$v.admission.title.$error">Title is required</span>
              </div>
              <div class="col-6 form-group">
                <label for class="float-left">Order</label>
                <input
                  type="number"
                  min="1"
                  class="form-control"
                  v-model="admission.position"
                  placeholder="Position"
                />
              </div>
              <div class="col-12 form-group">
                <label for class>Description</label>

                <ckeditor v-model="admission.description"></ckeditor>
              </div>

              <div class="col-2 form-group">
                <b-form-checkbox
                  v-model="admission.is_active"
                  name="check-button"
                  class
                  switch
                >Status</b-form-checkbox>
                <span class="text-danger" v-if="$v.admission.is_active.$error">Status is required</span>
              </div>
              <div class="col-12 mt-5">
                <div class="float-right">
                  <v-btn
                    class="btn btn-light-primary font-weight-bold px-9 py-4 my-3 font-size-3 mx-4"
                    depressed
                    @click="redirectTo"
                  >Cancel</v-btn>

                  <v-btn
                    class="text-primary btn btn-primary btn font-weight-bold px-9 py-4 my-3 font-size-3 mx-4"
                    depressed
                    @click="createOrUpdate"
                    :loading="isBusy"
                  >Save</v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";

import AdmissionProcessService from "@/core/services/level/program/admission-process/AdmissionProcessService";
import ProgramService from "@/core/services/level/program/ProgramService";

const admissionProcess = new AdmissionProcessService();
const programService = new ProgramService();

export default {
  name: "AdmissionProces-new",
  validations: {
    admission: {
      title: { required },
      is_active: { required }
    }
  },
  data() {
    return {
      isBusy: false,
      program: {},
      admission: {
        id: null,
        title: null,
        position: null,
        description: null,
        type: null,
        is_active: true
      },
      edit: false
    };
  },
  mounted() {
    if (this.id) {
      this.getAdmissionProcess(this.id);
      this.edit = true;
    }
    this.getProgram();
  },
  computed: {
    program_short_code() {
      return this.$route.params.program;
    },
    id() {
      return this.$route.params.process_id ? this.$route.params.process_id : false;
    }
  },
  methods: {
    getProgram() {
      programService.getByAbstract(this.program_short_code).then(response => {
        this.program = response.data.program;
        this.admission.program_id = this.program.id;
      });
    },
    getAdmissionProcess(id) {
      admissionProcess.show(id).then(response => {
        this.admission = response.data.admission;
        this.admission.is_active ? (this.admission.is_active = true) : false;
      });
    },
    redirectTo() {
      this.$router.push({ name: "admission-process" });
    },

    saveAdmissionProcess() {
      this.isBusy = true;
      admissionProcess
        .store(this.admission)
        .then(response => {
          this.isBusy = false;
          this.$snotify.success("Information added");
          this.redirectTo();
        })
        .catch(error => {
          this.isBusy = false;
          this.$snotify.error("Something Went Wrong");
        });
    },

    createOrUpdate() {
      this.$v.admission.$touch();
      if (this.$v.admission.$error) {
        setTimeout(() => {
          this.$v.admission.$reset();
        }, 3000);
      } else {
        let fd = this.generateFd();
        if (this.edit) {
          this.updateAdmissionProcess();
        } else {
          this.saveAdmissionProcess();
        }
      }
    },
    updateAdmissionProcess() {
      this.isBusy = true;
      admissionProcess
        .update(this.admission.id, this.admission)
        .then(response => {
          this.isBusy = false;
          this.$snotify.success("Information updated");
          this.redirectTo();
        })
        .catch(error => {
          this.isBusy = false;
          this.$snotify.error("Something Went Wrong");
        });
    },
    generateFd() {
      let fd = new FormData();
      for (let key in this.admission) {
        if (
          key == "image" &&
          this.admission["image"] &&
          this.admission["image"] != null
        ) {
          fd.append("image", this.admission[key]);
        } else if (key == "is_active" && this.admission["is_active"]) {
          fd.append("is_active", this.admission ? 1 : 0);
        } else {
          fd.append(key, this.admission[key]);
        }
      }
      return fd;
    }
  }
};
</script>
